import React,{ useEffect } from 'react'
import { useLocation } from "@reach/router"
//import axios from 'axios';

import SEOMetaData from '../components/background/SEOMetaData.js';
import {InterestTracker} from "../components/background/InterestTracker.js"

import GlobalNav from "../components/navigation/GlobalNav"
import Footer from "../components/navigation/Footer"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../styles/home.css'


import specialtyGroupUrl from '../images/home-specialty-group.jpg';
import specialtyHawaiiUrl from '../images/home-specialty-hawaii.jpg';
import specialtyDisneyUrl from '../images/home-specialty-disney.jpg';

export default function HomePage() {

    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Home',location); 
    },[location]);

  return (
    <main>
        <SEOMetaData
        title={'It\'s Time to Plan That Vacation'}
        description={'Personalized, curated travel experiences for escaping with friends, reconnecting with family, branded events, and solo adventures!'}
        image={'/images/SocialMediaImage-Home.jpg'}
        />

        <Container className="home-hero no-gutter">
            <GlobalNav /> 
            <iframe title="Splash" width="100%" height="550" frameBorder="0" src="https://storage.googleapis.com/outward-website-static-resources/home-hero/2021TemporaryShingle/HomeHero-TempShingle.html"></iframe>
        </Container>
        <Container className="no-gutter">
            <div id="Intro">

                <div id="IntroBGFlex">
                    <div className="left-border">
                        <svg id="IntroLefttBorder" className="introBG" data-name="Intro Left Border" xmlns="http://www.w3.org/2000/svg" width="55" height="300" viewBox="0 0 55 300">
                            <path id="Intro_Left_Outer_Border" data-name="Left Outer Border" class="left-outer-border" d="M27,300H0s31-19,31-112V24L42,12V216C40.5,283,27,300,27,300Z"/>
                            <path id="Intro Left_Inner_Border" data-name="Left Inner Border" class="inner-borders" d="M42,300H31s14.5-9,15-111V9l5-5V214C51.5,273,42,300,42,300Z"/>
                            <path id="Intro_Left_Text_BG" data-name="Text BG" class="text-bg" d="M74,299.535s-10-23-10-100v-200H55v200C55,276.535,45.5,300,45.5,300Z"/>
                        </svg>
                    </div>
                    <div className="text-area">
                        <div id="IntroText">
                        <h4>Relax&nbsp;...&nbsp;Discover&nbsp;...&nbsp;Reconnect ...&nbsp;Explore&nbsp;...&nbsp;Refresh&nbsp;...&nbsp;Dream</h4>
                        <p>
                            <span>There are many reasons to travel. It's not just about <i>where</i> you're going, 
                            but <i>why</i> you want to be there. </span> 
                            <span>At Outward Travel, our passion is crafting
                            fulfilling experiences that fit your budget and exceed your expectations. </span>
                            <span className="hidden-xs">Let's have a no-obligation, no-pressure chat to discover what Outward can do for you.</span>
                        </p>
                        <p>Call us at (720) 828-7665 or email <a href="mailto:go@outward.travel">go@outward.travel</a></p>
                        </div>                    
                    </div>
                    <div className="right-border">
                        <svg id="IntroRightBorder" className="introBG" data-name="Intro Right Border" xmlns="http://www.w3.org/2000/svg" width="55" height="300" viewBox="0 0 55 300">
                            <path id="Intro_Right_Text_BG" data-name="Text BG" class="text-bg" d="M9.9809,300.535s-10-23-10-100V.535h-9v200c0,77-9.5,100.465-9.5,100.465Z"/>
                            <path id="Intro_Right_Outer_Border" data-name="Right Outer Border" class="right-outer-border" d="M28,300H55S24,281,24,188V24L13,12V216C14.5,283,28,300,28,300Z"/>
                            <path id="Intro_Right_Inner_Border" data-name="Right Inner Border" class="inner-borders" d="M13,300H24S9.5,291,9,189V9L4,4V214C3.5,273,13,300,13,300Z"/>
                        </svg>
                    </div>
                </div>
                
            </div>
        </Container>
        <Container className="no-gutter" id="Specialties">
            <h2>
                <span>Our Specialties</span>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="2001" height="59" viewBox="0 0 2001 59">

                    <path class="text-bg" d="M875.8819,59" transform="translate(1.1181)"/>
                    <path class="inner-swoop" d="M863.8819,59h-21s-13-3-24-18a112.5423,112.5423,0,0,1-17-36s5,15,23,33C843.3886,56.5068,863.8819,59,863.8819,59Z" transform="translate(1.1181)"/>
                    <path class="text-bg" d="M836.8819,59h-838V0h798s4.3584,17.6655,11,30C821.8819,56,836.8819,59,836.8819,59Z" transform="translate(1.1181)"/>
                    <path class="outer-swoop" d="M899.8819,59h-27s-23.2887-4.6309-40-18c-20-16-25-31-25-31s11,21,33,33c26.0574,14.2131,59,15,59,15Z" transform="translate(1.1181)"/>
                    <rect class="keyline" x="901" y="58" width="1100" height="1"/>
                    <polygon class="left-angle" points="0 0 0 60 30 60 0 0"/>
                </svg>
            </h2>
            <Row xs={1} sm={1} md={3} >
                <Col>
                    <img src={specialtyGroupUrl} 
                         alt="Group of Friends on Vacation"
                         width="100%"
                    />
                    <h3>
                        <span>Group Travel<span className="hidden-md hidden-lg"> & Events</span></span>
                        <svg data-name="Subheading BG" xmlns="http://www.w3.org/2000/svg" width="387" height="43" viewBox="0 0 387 43"><polygon class="drop-shadow" points="374 43 0 43 0 4 387 4 374 43"/><polygon class="main-bg" points="370 39 0 39 0 0 383 0 370 39"/></svg>
                    </h3>
                    <p>As the world reopens, reconnect with friends and family someplace new. From beach-side drinks to adventures at sea, Outward Travel specializes in planning group trips that everyone will love.</p>
                </Col>
                <Col>
                    <img src={specialtyHawaiiUrl} 
                         alt="Hawaiian Coast"
                         width="100%"
                    />
                    <h3>
                        <span>Hawai'i</span>
                        <svg data-name="Subheading BG" xmlns="http://www.w3.org/2000/svg" width="387" height="43" viewBox="0 0 387 43"><polygon class="drop-shadow" points="374 43 0 43 0 4 387 4 374 43"/><polygon class="main-bg" points="370 39 0 39 0 0 383 0 370 39"/></svg>
                    </h3>
                    <p>The Big Island of Hawai'i is one of the most enchanting places in the world. Go from a morning swim on a black sand beach, to lunch by a volcano, to an afternoon on a guided ATV tour, and you don't even need a passport.</p>
                </Col>
                <Col>
                    <img src={specialtyDisneyUrl} 
                         alt="Mickey Mouse at Walt Disney World"
                         width="100%"
                    />
                    <h3>
                        <span>Disney World</span>
                        <svg data-name="Subheading BG" xmlns="http://www.w3.org/2000/svg" width="387" height="43" viewBox="0 0 387 43"><polygon class="drop-shadow" points="374 43 0 43 0 4 387 4 374 43"/><polygon class="main-bg" points="370 39 0 39 0 0 383 0 370 39"/></svg>
                    </h3>
                    <p>Disney World is a magical family vacation, but it might be even more fun without kids in tow. At Outward Travel, it's one of our favorite vacations, and we'll happily give you scoop on making it magical for you and your friends. </p>
                </Col>
            </Row>
        </Container>

        <Footer />
    </main>
  )
}


// export default HomePage

